import Client from "@/repositories/Clients/AxiosClient";
import Config from "@/repositories/Clients/AxiosDefaultConfig";

const resource = `${Config.basePath}/v1/website/notification`;

export default {
    index(payload = {}) {
        return Client.get(`${resource}`, payload);
    },
    count(payload) {
        return Client.get(`${resource}/count`, payload);
    },
    readAll(payload) {
        return Client.get(`${resource}/read_all`, payload);
    },
};
