import Client from "@/repositories/Clients/AxiosClient";
import Config from "@/repositories/Clients/AxiosDefaultConfig";
import { prepareSearchParams } from "@/libs/helper";
const resource = `${Config.basePath}/v1/website/okr_setting`;

export default {

  index(payload) {
    return Client.get(resource, {
      params: prepareSearchParams(payload),
    });
  },
  show(id, payload = {}) {
    return Client.get(`${resource}/${id}`, payload);
  },
  
  create(payload) {
    return Client.post(resource, payload);
  },


  update(id, payload) {
    return Client.put(`${resource}/${id}`, payload);
  },
  showOkrSetting(id, payload = {}) {
    return Client.get(`${resource}/show/${id}`, payload);
  },
};
