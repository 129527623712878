export const initialAbility = [
  {
    action: "read",
    subject: "auth",
  },
  {
    action: "read",
    subject: "profile",
  },

  // For: side menu
  // {
  //   action: 'read',
  //   subject: 'dashboard',
  // },
  // {
  //   action: 'read',
  //   subject: 'okr',
  // },
  // {
  //   action: 'read',
  //   subject: 'okrSetting',
  // },
  // {
  //   action: 'read',
  //   subject: 'reviewOkrSetting',
  // },
  // {
  //   action: 'read',
  //   subject: 'history',
  // },
];

export const initialAbilityConfig = [
  {
    action: "read",
    subject: "dashboard",
  },
  {
    action: "read",
    subject: "okrSetting",
  },
  {
    action: "read",
    subject: "reviewOkrSetting",
  },
];

export const _ = undefined;
