import Client from "@/repositories/Clients/AxiosClient";
import Config from "@/repositories/Clients/AxiosDefaultConfig";

const resource = `${Config.basePath}/v1/website/year_end_review_consider_question`;

export default {

  options(payload) {
    return Client.get(`${resource}/option`, {
      params: payload,
    });
  },
};
