import moment from 'moment';
import currency from "currency.js";
import { decamelize, camelize } from 'humps';
import store from '@/store';

export const caclulateOffset = (page, limit) => {
  return (page - 1) * limit;
};

export const calculateDatesByPeriod = (period) => {
  if (period === 'today') {
    return [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
  } else if (period === 'yesterday') {
    return [moment().subtract(1, 'day').format('YYYY-MM-DD'), moment().subtract(1, 'day').format('YYYY-MM-DD')];
  } else if (period === 'thisWeek') {
    return [moment().startOf('isoWeek').format('YYYY-MM-DD'), moment().endOf('isoWeek').format('YYYY-MM-DD')];
  } else if (period === 'lastWeek') {
    return [moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'), moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')];
  } else if (period === 'thisMonth') {
    return [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')];
  } else if (period === 'lastMonth') {
    return [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')];
  }

  return [];
}

export const State = {
  REJECTED: "rejected",
  DRAFT: "draft",
  SUBMITTED: "submitted",
  APPROVED: "approved",
  HODREJECTED: "hodrejected",
  HODAPPROVED: "hodapproved",
  GMREJECTED: "gmrejected",
  GMAPPROVED: "gmapproved",
  HRREJECTED: "hrrejected",
  HRAPPROVED: "hrapproved",
  MANAGEMENTAPPROVED: "managementapproved",
  MANAGEMENTREJECTED: "managementrejected",
}

export const getTranslate = (data, field, lang = "en") => {
  field = camelize(`${field}_${lang}`);

  return data[field];
};

export const convertToDouble = (value) => {
  if (!value) {
    return "0.00";
  }

  return Number.parseFloat(value).toFixed(2);
}

export const getMidYearStateColor = (value) => {
  const state = {
    pending: "pending",
    ontrack: "on",
    offtrack: "off"
  };

  if (value.toLowerCase().includes(state.offtrack)) {
    return "danger";
  } else if (value.toLowerCase().includes(state.pending)) {
    return "warning";
  } else if (value.toLowerCase().includes(state.ontrack)) {
    return "success";
  }
}

export const getOverAllYearEndStatus = (value) => {

  if (value >= 97 && value <= 100) {
    return {
      name: "Exceptional",
      class: "bg-exceptional"
    };
  } else if (value >= 91 && value <= 96.99) {
    return {
      name: "Superior",
      class: "bg-superior"
    };
  } else if (value >= 70 && value <= 90.99) {
    return {
      name: "Satisfactory",
      class: "bg-satisfactory"
    };
  }
  else if (value >= 50 && value <= 69.99) {
    return {
      name: "Improvement Required",
      class: "bg-improvementrequired"
    };
  } else {
    return {
      name: "Unacceptable",
      class: "bg-unacceptable"
    };
  }
}

export const getYearEndStateColor = (value) => {
  const state = {
    achieved: "achieved",
    underachieved: "under",
    ongoing: "ongoing"
  };

  if (value.toLowerCase().includes(state.underachieved)) {
    return "danger";
  } else if (value.toLowerCase().includes(state.ongoing)) {
    return "warning";
  } else if (value.toLowerCase().includes(state.achieved)) {
    return "success";
  }
}
export const checkEmployeeSignature = (value) => {
  if (value.toLowerCase().includes('employee')) {
    return true;
  } else {
    return false;
  }
}
export const checkSupervisorSignature = (value) => {
  if (value.toLowerCase().includes('supervisor')) {
    return true;
  } else {
    return false;
  }
}
export const checkHodSignature = (value) => {
  if (value.toLowerCase().includes('hod')) {
    return true;
  } else {
    return false;
  }
}

export const checkGmSignature = (value) => {
  if (value.toLowerCase().includes('gm') || value.toLowerCase().includes('general')) {
    return true;
  } else {
    return false;
  }
}

export const validateSignture = (value) => {
  console.log(value);
}

export const getNoNumber = (value) => {

  if (value == '1') {
    return "1";
  }
  if (value == '2') {
    return "2";
  }
  if (value == '3') {
    return "3";
  }
  if (value == '4') {
    return "4";
  }
  if (value == '5') {
    return "5";
  }
  if (value == '6') {
    return "1";
  }
  if (value == '7') {
    return "2";
  }
  if (value == '8') {
    return "3";
  }
  if (value == '9') {
    return "4";
  }
  if (value == '10') {
    return "5";

  }
}

export const getNotificationStatus = (value) => {

  const status = {
    reviewer: "reviewer",
    requester: "requester",

  }
  if (value.toLowerCase().includes(status.requester)) {
    return {
      icon: 'XIcon',
      color: 'light-danger',
      cardColor: 'bg-light-danger',
      route: 'okr-setting'
    };

  } else if (value.toLowerCase().includes(status.reviewer)) {
    return {
      icon: 'ClockIcon',
      color: 'light-warning',
      cardColor: 'bg-light-warning',
      route: 'supervisor-review'
    };
  } else {
    return {
      icon: 'CheckIcon',
      color: 'light-success',
      cardColor: 'bg-light-success',
      route: 'hod-review',
    };
  }
}

export const getState = (value) => {
  const state = {
    rejected: "rejected",
    draft: "draft",
    submitted: "submitted",
    approved: "approved",
    hodapproved: "hodapproved",
    hodrejected: "hodrejected",
    gmapproved: "gmapproved",
    gmrejected: "gmrejected",
    hrapproved: "hrapproved",
    hrrejected: "hrrejected",
    managementrejected: "managementrejected",
    managementapproved: "managementapproved",
  };

  if (value === state.rejected) {
    return {
      name: 'status.supervisorRejected',
      color: "danger"
    };
  } else if (value === state.draft) {
    return {
      name: 'status.draft',
      color: "light-dark"
    };
  } else if (value === state.submitted) {
    return {
      name: 'status.submitted',
      color: "submitted"
    };
  } else if (value === state.approved) {
    return {
      name: 'status.supervisorApproved',
      color: "supervisor-approved"
    };
  } else if (value === state.hodapproved) {
    return {
      name: 'status.hodapproved',
      color: "hod-approved"
    };
  } else if (value === state.hodrejected) {
    return {
      name: 'status.hodreject',
      color: "danger"
    };
  } else if (value === state.gmapproved) {
    return {
      name: 'status.gmapproved',
      color: "gm-approved"
    };
  } else if (value === state.gmrejected) {
    return {
      name: 'status.gmrejected',
      color: "danger"
    };
  } else if (value === state.hrapproved) {
    return {
      name: 'status.hrapproved',
      color: "hr-approved"
    };
  } else if (value === state.hrrejected) {
    return {
      name: 'status.hrrejected',
      color: "danger"
    };
  }else if(value === state.managementrejected){
    return {
      name: 'status.managementrejected',
      color: "danger"
    };
  } else if(value === state.managementapproved){
    return {
      name: 'status.managementapproved',
      color: "management-approved"
    };
  }
  else {
    return {
      name: 'status.notStarted',
      color: "secondary"
    };
  }
}

export const currencyFormat = (value, currencyCode) => {
  if (typeof value === 'undefined') {
    return '';
  }
  let val = value;
  let symbol = '';

  if (currencyCode === 840) {
    const USD = value => currency(value);
    return USD(value).format();
  } else if (currencyCode === 116) {
    const RIEL = value => currency(value, { precision: 2, symbol: '៛' });
    return RIEL(value).format()
  }

  return currency(value);
}
export const prepareSearchParams = (payload) => {
  const params = {
    ...payload,
    limit: store.state.pagination.perPage,
    page: payload.page,
    sortby: payload.order || "id",
    sort: payload.sort || "desc",
    archived: payload.archived ? 1 : 0,
  };

  return params;
}

export const makeid = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

export const mapAbility = (data, newAbility, initialAbility) => {
  if (data.isReadonly) {
    data.ability.forEach((x) => {
      x.action = "read";
      return x;
    });
  }

  newAbility = [...data.ability, ...initialAbility];
  return newAbility;
}