import Client from "@/repositories/Clients/AxiosClient";
import Config from "@/repositories/Clients/AxiosDefaultConfig";

const resource = `${Config.basePath}/v1/website/step_information`;

export default {
    
  show(payload) {
    return Client.get(resource, {
      params: payload,
    });
  },
};
