import Client from "@/repositories/Clients/AxiosClient";
import Config from "@/repositories/Clients/AxiosDefaultConfig";

const resource = `${Config.basePath}/v1/website/mid_year_review`;

export default {
  show(id, payload = {}) {
    return Client.get(`${resource}/${id}`, payload);
  },
  create(payload) {
    return Client.post(resource, payload);
  },
  update(id, payload) {
    return Client.put(`${resource}/${id}`, payload);
  },
};