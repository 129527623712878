import ProfileRepository from "./Api/ProfileRepository";
import RoleRepository from "./Api/RoleRepository";
import PermissionRepository from "./Api/PermissionRepository";
import FileRepository from "./Api/FileRepository";
import EmployeeRepository from "./Api/EmployeeRepository";
import StepInformationRepository from "./Api/StepInformationRepository";
import ObjectiveTypeRepository from "./Api/ObjectiveTypeRepository";
import OkrSettingRepository from "./Api/OkrSettingRepository";
import ObjectiveRepository from "./Api/ObjectiveRepository";
import MidYearReviewStatusRepository from "./Api/MidYearReviewStatusRepository";
import MidYearReviewRepository from "./Api/MidYearReviewRepository";
import YearEndReviewStatusRepository from "./Api/YearEndReviewStatusRepository";
import YearEndReviewSignatureLabelRepository from "./Api/YearEndReviewSignatureLabelRepository";
import YearEndReviewRepository from "./Api/YearEndReviewRepository";
import MidYearReviewCommentQuestionRepository from "./Api/MidYearReviewCommentQuestionRepository";
import YearEndReviewCommentQuestionRepository from "./Api/YearEndReviewCommentQuestionRepository";
import YearEndReviewConsiderationRepository from "./Api/YearEndReviewConsiderationRepository";
import NotificationRepository from "./Api/NotificationRepository";
import CompanyRepository from "./Api/CompanyRepository";
import DepartmentRepository from "./Api/DepartmentRepository";

const repositories = {
    'profile': ProfileRepository,
    'role': RoleRepository,
    'permission': PermissionRepository,
    'file': FileRepository,
    'employee':EmployeeRepository,
    'stepInformation':StepInformationRepository,
    'objectiveType': ObjectiveTypeRepository,
    'objective': ObjectiveRepository,
    'okrSetting': OkrSettingRepository,
    'midYearReviewStatus':MidYearReviewStatusRepository,
    'midYearReview':MidYearReviewRepository,
    'midYearCommentQuestion':MidYearReviewCommentQuestionRepository,
    'yearEndReviewStatus':YearEndReviewStatusRepository,
    'yearEndReviewCommentQuestion':YearEndReviewCommentQuestionRepository,
    'yearEndReviewConsiderQuestion':YearEndReviewConsiderationRepository,
    'yearEndReviewSignatureLabel':YearEndReviewSignatureLabelRepository,
    'yearEndReview':YearEndReviewRepository,
    'notification':NotificationRepository,
    'company': CompanyRepository,
    'department': DepartmentRepository,
};

export default {
    get: name => repositories[name]
};
