import Client from "@/repositories/Clients/AxiosClient";
import Config from "@/repositories/Clients/AxiosDefaultConfig";
import { prepareSearchParams } from "@/libs/helper";

const resource = `${Config.basePath}/v1/website/objective`;

export default {
  index(payload) {
    return Client.get(resource, {
      params: prepareSearchParams(payload),
    });
  },

};
