import Vue from 'vue';
import i18n from "@/libs/i18n";
import VueRouter from 'vue-router';
import { $themeConfig } from "@themeConfig";

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import errors from './routes/errors';
import auth from './routes/auth';
import profile from './routes/profile';


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'breadcrumb.dashboard',
        resource: 'dashboard',
        action: 'read',
        breadcrumb: [
          {
            text: 'breadcrumb.dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/okr-setting/:id',
      name: 'okr-setting',
      component: () => import('@/views/okrSettingReview/MainOkrTab.vue'),
      meta: {
        pageTitle: 'breadcrumb.okrSetting',
        action: 'read',
        resource: 'okrSetting',
        breadcrumb: [
          {
            text: 'breadcrumb.okrSetting',
            active: true,
          },
        ],
      },
    },
    {
      path: '/supervisor-review/:id',
      name: 'supervisor-review',
      component: () => import('@/views/supervisor/okrSettingReview/MainOkrTab.vue'),
      meta: {
        pageTitle: 'breadcrumb.reviewOkrSetting',
        action: 'read',
        resource: 'reviewOkrSetting',
        breadcrumb: [
          {
            text: 'breadcrumb.reviewOkrSetting',
            active: true,
          },
        ],
      },
    },
    {
      path: '/hod-review/:id',
      name: 'hod-review',
      component: () => import('@/views/hod/okrSettingReview/MainTab.vue'),
      meta: {
        pageTitle: 'breadcrumb.reviewOkrSetting',
        action: 'read',
        resource: 'reviewOkrSetting',
        breadcrumb: [
          {
            text: 'breadcrumb.reviewOkrSetting',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gm-review/:id',
      name: 'gm-review',
      component: () => import('@/views/gm/okrSettingReview/MainTab.vue'),
      meta: {
        pageTitle: 'breadcrumb.reviewOkrSetting',
        action: 'read',
        resource: 'reviewOkrSetting',
        breadcrumb: [
          {
            text: 'breadcrumb.reviewOkrSetting',
            active: true,
          },
        ],
      },
    },

    ...errors,
    ...auth,
    ...profile,
    // ...user,
    // ...role,    
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Method using PUSH
const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return RouterPush.call(this, to).catch(err => err)
}

// Method using Replace
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return RouterReplace.call(this, to).catch(err => err)
}

router.beforeEach((to, _, next) => {
  Vue.nextTick(() => {
    const { appName } = $themeConfig.app;
    document.title = `${to.meta.title ? i18n.tc(to.meta.title) : i18n.tc(to.meta.pageTitle)} | ${appName}`;
  });

  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {

    // Redirect to login if not logged in
    if (!isLoggedIn){
      
     return next({ name: 'login' })
    }
    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? 'admin' : null, '/'))
  }

  return next()
})

export default router
