import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareSearchParams } from '@/libs/helper';
const resource = `${Config.basePath}/v1/website/employee`;

export default {

    showEmployeeProfile(payload = {}) {
        return Client.get(`${resource}`,{
          params:prepareSearchParams(payload),
        });
    },

    getListEmployee(id,payload) {
        return Client.get(`${resource}/supervisor/${id}`, {
          params: prepareSearchParams(payload),
        });
      },

    options() {
        return Client.get(`${resource}/option`, {
          params: {supervisorId:JSON.parse(localStorage.getItem("userData")).id},
        });
      },   
};
